
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Table, Row, Select, Space, Tooltip, message, Modal, Checkbox, Skeleton } from 'antd'
import { DeleteOutlined, DownloadOutlined, FileExcelOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectClass } from '../../../select/SelectClass';
import TableView from '../../../../contents/AntTableResponsive';
import $ from 'jquery';
import jsPDF from "jspdf";
import { pdfDataL, ppowerdbypdf, pdatepdf, ldatepdf, lpowerdbypdf } from '../../../utils/pdf';
import ReactExport from "react-export-excel";
import { SelectClassConfig } from '../../../select/SelectClassConfig';
import { PrinterOutlined } from '@ant-design/icons';
import ReactToPrint from 'react-to-print';
declare module 'react' {
    interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
        // extends React's HTMLAttributes
        name?: string;
    }
}
require('jspdf-autotable');

const ExcelFile: any = ReactExport.ExcelFile;
const ExcelSheet: any = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn: any = ReactExport.ExcelFile.ExcelColumn;

const ComponentToPrint = React.forwardRef((props: any, ref: any) => {
    const { data, info } = props;
    const cols = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true },
        { title: 'Group', dataIndex: 'groupName', key: 'groupName', showOnResponse: true, showOnDesktop: true },
        { title: 'Father', dataIndex: 'fatherName', key: 'fatherName', showOnResponse: true, showOnDesktop: true },
        { title: 'Mother', dataIndex: 'motherName', key: 'motherName', showOnResponse: true, showOnDesktop: true },
        { title: 'Gender', dataIndex: 'studentGender', key: 'studentGender', showOnResponse: true, showOnDesktop: true },
        { title: 'Religion', dataIndex: 'studentReligion', key: 'studentReligion', showOnResponse: true, showOnDesktop: true },
        { title: 'Mobile No.', dataIndex: 'guardianMobile', key: 'guardianMobile', showOnResponse: true, showOnDesktop: true },
        {
            title: 'Image',
            key: 'imageName',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <img src={"data:image/png;base64," + record?.imageName} alt="" height="50" width="50" className='stImage' />
            ),
        },
    ];
    return (
        <div className='print-source' ref={ref} >
            <div style={{ textAlign: "center" }}>
                <h2>{info?.instituteName}</h2>
                <p><h3>{info?.address}</h3></p>
            </div>
            <hr />
            <div style={{ textAlign: "center", fontWeight: "bold", marginBottom: 5 }}>Section wise student list of Section: {$(".sectionSelect").text()} and Academic Year: {$(".yearSelect").text()}</div>
            <Table pagination={false} bordered={true} dataSource={data} columns={cols} />

        </div>
    );
});

export default function SectionWiseReport() {

    const [sectionWiseReportForm] = Form.useForm();
    const fetchSectionsWiseReport = useStoreActions((state) => state.student.fetchSectionsWiseReport);
    const sectionWiseListReport = useStoreState((state) => state.student.sectionWiseListReport);
    const loading = useStoreState((state) => state.student.loading);
    const instituteInfo = useStoreState((state) => state.auth.instituteInfo);
    const formSubmit = (value) => {
        let postData: any = {
            academicYear: value.yearId,
            classconfigid: value.sectionId
        }
        fetchSectionsWiseReport(postData);
    }

    const studentListColumn = [
        { title: 'Student ID', dataIndex: 'customStudentId', key: 'customStudentId', showOnResponse: true, showOnDesktop: true },
        { title: 'Student Name', dataIndex: 'studentName', key: 'studentName', showOnResponse: true, showOnDesktop: true },
        { title: 'Roll', dataIndex: 'studentRoll', key: 'studentRoll', showOnResponse: true, showOnDesktop: true },
        { title: 'Group', dataIndex: 'groupName', key: 'groupName', showOnResponse: true, showOnDesktop: true },
        { title: 'Date of Birth', dataIndex: 'studentDOB', key: 'studentDOB', showOnResponse: true, showOnDesktop: true },
        { title: 'Father', dataIndex: 'fatherName', key: 'fatherName', showOnResponse: true, showOnDesktop: true },
        { title: 'Mother', dataIndex: 'motherName', key: 'motherName', showOnResponse: true, showOnDesktop: true },
        { title: 'Gender', dataIndex: 'studentGender', key: 'studentGender', showOnResponse: true, showOnDesktop: true },
        { title: 'Religion', dataIndex: 'studentReligion', key: 'studentReligion', showOnResponse: true, showOnDesktop: true },
        { title: 'Mobile No.', dataIndex: 'guardianMobile', key: 'guardianMobile', showOnResponse: true, showOnDesktop: true },
        {
            title: 'Image',
            key: 'imageName',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
               // <img src={"data:image/png;base64," + record?.imageName} alt="" height="50" width="50" className='stImage' />
                <img src={record.studentImageLink} height='50' width='50' />
            ),
        },
    ];

    function exportPdf() {

        var doc = new jsPDF("l", "mm", "a4");

        var details = `Section wise list of ${$(".sectionSelect").text()} and Academic Year-${sectionWiseReportForm.getFieldValue("yearId")}`;
        var detailsx = `Section wise list of Section: ${$(".sectionSelect").text()} and Academic Year: ${sectionWiseReportForm.getFieldValue("yearId")}`;
        pdfDataL(doc, "");
        doc.text(detailsx, 150, 40, 'center');
        var totalPagesExp = "{total_pages_count_string}";

        var pageContent = function (data) {
            // FOOTER
            var str = lpowerdbypdf + data.pageCount;
            if (typeof doc.putTotalPages === 'function') {
                str = str + " of " + totalPagesExp + ldatepdf;
            }
            doc.setFontSize(8);
            var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            doc.text(str, data.settings.margin.right, pageHeight - 10);
        };


        var col = [
            "Student ID",
            "Student Name",
            "Roll",
            "Group",
            "Date of Birth",
            "Father",
            "Mother",
            "Gender",
            "Religion",
            "Mobile No."
        ].filter(Boolean);

        var rows: any = [];


        sectionWiseListReport.forEach(element => {
            var temp: any = [
                element.customStudentId,
                element.studentName,
                element.studentRoll,
                element.groupName,
                element.studentDOB,
                element.fatherName,
                element.motherName,
                element.studentGender,
                element.studentReligion,
                element.guardianMobile,
            ];
            rows.push(temp);
        });


        let first = doc.autoTable.previous;
        doc.autoTable(col, rows, {
            startY: 45,
            showHeader: "firstPage",
            theme: 'grid',
            styles: {
                overflow: 'linebreak',
            },
            headerStyles: {
                // fillColor: [105, 105, 105],
                // textColor: [255, 255, 255],
                // fontSize: 10
                lineWidth: .01,
                lineColor: [224, 224, 224]
            },
            columnStyles: {
                0: { overflow: 'linebreak', columnWidth: 25 },
                2: { overflow: 'linebreak', columnWidth: 12 },
                3: { overflow: 'linebreak', columnWidth: 20 },
                6: { overflow: 'linebreak', columnWidth: 18 },
                7: { overflow: 'linebreak', columnWidth: 18 },
                8: { overflow: 'linebreak', columnWidth: 30 },
            },
            addPageContent: pageContent,
        });


        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
        doc.setPage(1 + doc.internal.getCurrentPageInfo().pageNumber - doc.autoTable.previous.pageCount);
        doc.save(details + ".pdf");

    }

    const componentRef: any = useRef();

    return (
        <>
            <Card title="Section Wise Student List">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 20, offset: 4 }} xl={{ span: 20, offset: 4 }}>
                        <Form
                            layout="vertical"
                            id="classConfigInfo"
                            onFinish={formSubmit}
                            form={sectionWiseReportForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="yearId"
                                        label="Academic Year"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select year" },
                                        ]}
                                    >
                                        <SelectAcademicYear />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                    <Form.Item
                                        name="sectionId"
                                        label="Select Section"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please select section" },
                                        ]}
                                    >
                                        <SelectClassConfig />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                                    <Space size="small" >
                                        <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                            Search
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
                <Skeleton loading={loading} paragraph={{ rows: 10 }} />
                <Row style={{ display: sectionWiseListReport.length > 0 ? '' : 'none' }}>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }}>
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns: studentListColumn,
                                rowKey: "identificationId",
                                dataSource: sectionWiseListReport,
                                filterData: sectionWiseListReport,
                                pagination: true,
                                bordered: true,
                            }}
                            mobileBreakPoint={768}
                        />
                        {/* <Button type="primary" htmlType="submit" icon={<DeleteOutlined />} onClick={() => deleteStudent()} className="float-right">Delete</Button> */}
                    </Col>
                    <Col span={24}>
                        <Space size="small" style={{ float: "right" }}>
                            <ReactToPrint
                                trigger={() => <Button type='primary' icon={<PrinterOutlined />}>Image Print (Landscape)</Button>}
                                content={() => componentRef.current}
                            />
                            <ComponentToPrint ref={componentRef} data={sectionWiseListReport} info={instituteInfo} />
                            <ExcelFile
                                element={<Button type="primary" icon={<FileExcelOutlined />} >
                                    Download Excel
                                </Button>}
                                filename={`Section Wise Report of ${$(".sectionSelect").text()} and Academic Year ${sectionWiseReportForm.getFieldValue("yearId")}`}
                            >
                                <ExcelSheet data={sectionWiseListReport} name="Class Wise Report">
                                    <ExcelColumn label="Student ID" value="customStudentId" />
                                    <ExcelColumn label="Student Name" value="studentName" />
                                    <ExcelColumn label="Roll" value="studentRoll" />
                                    <ExcelColumn label="Group" value="groupName" />
                                    <ExcelColumn label="Date of Birth" value="studentDOB" />
                                    <ExcelColumn label="Father" value="fatherName" />
                                    <ExcelColumn label="Mother" value="motherName" />
                                    <ExcelColumn label="Gender" value="studentGender" />
                                    <ExcelColumn label="Religion" value="studentReligion" />
                                    <ExcelColumn label="Mobile No." value="guardianMobile" />
                                </ExcelSheet>
                            </ExcelFile>
                            <Button type="primary" icon={<DownloadOutlined />} onClick={() => exportPdf()} >PDF Download</Button>

                        </Space>
                    </Col>
                </Row>

            </Card>
        </>
    )
}
