import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, DatePicker, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../store/hooks/easyPeasy';
import Column from 'rc-table/lib/sugar/Column';
import { DeleteOutlined, FilePdfOutlined, SaveOutlined, SearchOutlined, SettingFilled, SwitcherOutlined, UploadOutlined } from '@ant-design/icons';
import TableView from '../../../contents/AntTableResponsive';
import moment from 'moment';
import axios from 'axios';
const ATTENDANCE_DEVICE = process.env.ATTENDANCE_DEVICE_URL

export default function ZktecoAttendanceProcess() {

    const [form] = Form.useForm();
 
    const staffZktecoDatas = useStoreState((state) => state.staff.staffZktecoRecords);
    const fetchStaffZktecoDatas = useStoreActions((state) => state.staff.fetchStaffZktecoRecords);
    const processStaffAttendance = useStoreActions((state) => state.staff.processStaffMachineAttendance);
    const [attendanceDate, setattendanceDate] = useState<any>(moment(new Date(), 'DD/MM/YYYY'));
    const loading = useStoreState((state) =>  state.staff.loading);
    const [formData, setFormData] = useState<any>();
    const [deviceId, setDeviceId] = useState<any>();
    const [sendSms, setsendSms] = useState<boolean>(false);

    const fetchZketekoIds = useStoreActions((state) => state.student.fetchZktecoDeviceIdList);
    const zketekoIds = useStoreState((state) => state.student.zktecoDeviceIdList);

    const columns = [
        { title: 'User ID', dataIndex: 'empCode', key: "empCode", showOnResponse: true, showOnDesktop: true },
        { title: 'Punch Date', dataIndex: 'checkDate', key: "checkDate", showOnResponse: true, showOnDesktop: true },
        { title: 'Punch Time', dataIndex: 'checkTime', key: "checkTime", showOnResponse: true, showOnDesktop: true },
        { title: 'Area', dataIndex: 'area', key: "area", showOnResponse: true, showOnDesktop: true },


    ];

    useEffect(() => {
        fetchZketekoIds();
        let localStoragedata = localStorage.getItem("insinfo")
        let localStorageparseData = JSON.parse(localStoragedata?localStoragedata:'' );
        setDeviceId(localStorageparseData?.deviceUserId);
    }, [])
 
    
    const formSubmit = (value) => {
        setFormData(value);
        let payload : any = {
            "attendanceDate": moment(value?.attendanceDate).format("YYYY-MM-DD")
        }
        
        fetchStaffZktecoDatas(payload);
    }

    const processData = () => {
        
        let details:any = [];
        staffZktecoDatas.map(item => {
            details.push({
                "punchDateString": item.checkDate,
                "punchTimeString": item.checkTime,
                "userId": item.empCode
            })
        })
        
        let payload = {
            "attendanceDate": attendanceDate,
            "details": details
        }
        processStaffAttendance(payload)
    }

    

    return (
        <>
            <Form
                layout="vertical"
                onFinish={formSubmit}
                id="basic-info"
                form={form}
            >
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                            name="attendanceDate"
                            label="Attendance Date"
                            className="title-Text"
                        >
                            <DatePicker
                                defaultValue={attendanceDate}
                                style={{ width: "100%"}}
                                placeholder="Select Date"
                                format={"YYYY/MM/DD"}
                                onChange={(e) => setattendanceDate(e)}
                            />
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Space size="small" >
                            <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                Search
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Skeleton loading={loading} paragraph={{rows:10}}/>
            <Row className='mt-30' style={{ display: staffZktecoDatas?.length > 0 ? "" : "none" }}>
                <Col span={24}>
                    <div className="datatable-responsive-demo">
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: staffZktecoDatas,
                                filterData: staffZktecoDatas,
                                pagination: true,
                                bordered: true,
                                rowKey: "id",
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                </Col>
                
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Button type="primary"  onClick={processData} className='mt-0' icon={<SettingFilled />} style={{ float: "right" }}>
                        Process
                    </Button>
                </Col>
            </Row>
        </>
    )
}