import React, { useEffect, useRef } from 'react'
import { useState } from 'react';
import { Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, Tabs, DatePicker, Skeleton } from 'antd'
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import Column from 'rc-table/lib/sugar/Column';
import { DeleteOutlined, FilePdfOutlined, SaveOutlined, SearchOutlined, SettingFilled, SwitcherOutlined, UploadOutlined } from '@ant-design/icons';
import TableView from '../../../../contents/AntTableResponsive';
import moment from 'moment';
import { SelectAcademicYear } from '../../../select/SelectAcademicYear';
import { SelectPeriod } from '../../../select/SelectPeriod';
import { SelectClassConfigMultiple } from '../../../select/SelectClassConfigMultiple';
import axios from 'axios';
const ATTENDANCE_DEVICE = process.env.ATTENDANCE_DEVICE_URL

export default function ZktecoAttendanceProcess() {

    const [form] = Form.useForm();
    const fetchStudentAttendanceAllPeriod = useStoreActions((state) => state.student.fetchStudentAttendanceAllPeriod);
    const studentAttendanceAutoProcessList = useStoreState((state) => state.student.studentAttendanceAutoProcessList);
    const setStudentAttendanceAutoProcessList = useStoreActions((state) => state.student.setStudentAttendanceAutoProcessList);
    const studentTxtFileSave = useStoreActions((state) => state.student.studentTxtFileSave);
    const [attendanceDate, setattendanceDate] = useState<any>(moment(new Date(), 'DD/MM/YYYY'));
    const loading = useStoreState((state) =>  state.student.loading);
    const [formData, setFormData] = useState<any>();
    const [deviceId, setDeviceId] = useState<any>();
    const [sendSms, setsendSms] = useState<boolean>(false);

    const fetchZketekoIds = useStoreActions((state) => state.student.fetchZktecoDeviceIdList);
    const zketekoIds = useStoreState((state) => state.student.zktecoDeviceIdList);

    const columns = [
        { title: 'User ID', dataIndex: 'empCode', key: "empCode", showOnResponse: true, showOnDesktop: true },
        { title: 'Punch Date', dataIndex: 'checkDate', key: "checkDate", showOnResponse: true, showOnDesktop: true },
        { title: 'Punch Time', dataIndex: 'checkTime', key: "checkTime", showOnResponse: true, showOnDesktop: true },
        { title: 'Area', dataIndex: 'area', key: "area", showOnResponse: true, showOnDesktop: true },


    ];

    useEffect(() => {
        fetchStudentAttendanceAllPeriod();
        fetchZketekoIds();
        let localStoragedata = localStorage.getItem("insinfo")
        let localStorageparseData = JSON.parse(localStoragedata?localStoragedata:'' );
        setDeviceId(localStorageparseData?.deviceUserId);
    }, [])
 
    
    
    async function getDeviceData(data) {
        try {
          const response = await fetch('https://api.shebashikkha.com/public/fetch/zkteco/data', {
            method: 'POST',
            headers: {
              'content-type': 'application/json',
            },
            body: JSON.stringify(data),
          }).then((response) => response.json())
          .then((data) => {
            if (data?.item.length > 0) {
                setStudentAttendanceAutoProcessList(data.item)
            }else{
                setStudentAttendanceAutoProcessList([])
                notification.error({ message: 'No data found' });
            }
            
          });          
        } catch (err) {
          console.log(err);
        }
      }
      

    const formSubmit = (value) => {
        setFormData(value);
        let data:any = {
            "attendanceDate": moment(value?.attendanceDate).format("YYYY-MM-DD"),
            "deviceIds": zketekoIds
        }
        getDeviceData(data);
    }

    const processData = () => {
        let details:any = [];
        studentAttendanceAutoProcessList.map(item => {
            details.push({
                "punchDateString": item.checkDate,
                "punchTimeString": item.checkTime,
                "userId": item.empCode
            })
        })
        let postData:any = {
            attendanceDate: moment(formData.attendanceDate).format("YYYY-MM-DD"),
            classConfigIds: formData.selectSection,
            periodId: formData.selectPeriod,
            absentSms:sendSms,
            details: details
        };     
        studentTxtFileSave(postData);
        setStudentAttendanceAutoProcessList([]);
    }

    

    return (
        <>
            <Form
                layout="vertical"
                onFinish={formSubmit}
                id="basic-info"
                form={form}
            >
                <Row gutter={8}>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                            name="attendanceDate"
                            label="Attendance Date"
                            className="title-Text"
                        >
                            <DatePicker
                                defaultValue={attendanceDate}
                                style={{ width: "100%"}}
                                placeholder="Select Date"
                                format={"YYYY/MM/DD"}
                                onChange={(e) => setattendanceDate(e)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                            name="selectPeriod"
                            label="Select period"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select period" },
                            ]}
                        >
                            <SelectPeriod />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Form.Item
                            name="selectSection"
                            label="Select section"
                            className="title-Text"
                            rules={[
                                { required: true, message: "Please select section" },
                            ]}
                        >
                            <SelectClassConfigMultiple />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={6} xl={6}>
                        <Space size="small" >
                            <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                Search
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Skeleton loading={loading} paragraph={{rows:10}}/>
            <Row className='mt-30' style={{ display: studentAttendanceAutoProcessList?.length > 0 ? "" : "none" }}>
                <Col span={24}>
                    <div className="datatable-responsive-demo">
                        <TableView
                            antTableProps={{
                                showHeader: true,
                                columns,
                                dataSource: studentAttendanceAutoProcessList,
                                filterData: studentAttendanceAutoProcessList,
                                pagination: true,
                                bordered: true,
                                rowKey: "routineId",
                            }}
                            mobileBreakPoint={768}
                        />
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>

                    <Checkbox value={sendSms} onChange={e => setsendSms(!sendSms)}>Send SMS</Checkbox>

                    <Button type="primary"  onClick={processData} className='mt-0' icon={<SettingFilled />} style={{ float: "right" }}>
                        Process
                    </Button>
                </Col>
            </Row>
        </>
    )
}