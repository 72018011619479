import React, { useEffect, useState } from 'react';
import { Tag, Button, Card, Col, Divider, Form, Input, InputNumber, Popconfirm, Row, Select, Space, Tooltip, message, Modal, Checkbox, notification, DatePicker, Skeleton } from 'antd';
import { DownloadOutlined, EditOutlined, SaveOutlined, SearchOutlined } from '@ant-design/icons';
import { useStoreActions, useStoreState } from '../../../../store/hooks/easyPeasy';
import moment from 'moment';
import { bloodData } from './admissiondata';
import TableView from '../../../../contents/AntTableResponsive';
import { SelectClassConfig3 } from '../../../select/SelectClassConfig3';
import { SelectGroup } from '../../../select/SelectGroup';
import { SelectAcademicYear4 } from '../../../select/SelectAcademicYear4';
import { SelectGroupbySection } from '../../../select/SelectGroupbySection';
const { Option } = Select;

export default function IdCardStudentInfo() {

    const [searchStudentForm] = Form.useForm();
    const [updateForm] = Form.useForm();
    const updateSingleStudentBasic = useStoreActions((state) => state.student.updateSingleStudentBasic);
    const fetchlistGroupbyClassConfig = useStoreActions((state) => state.student.fetchlistGroupbyClassConfig);
    const updateSingleStudentIdentification = useStoreActions((state) => state.student.updateSingleStudentIdentification);
    const downloadStudentIdCard = useStoreActions((state) => state.student.downloadStudentIdCard);
    const fetchsingleStudent = useStoreActions((state) => state.student.fetchsingleStudent);
    const singleStudent = useStoreState((state) => state.student.singleStudent);;
    const loading = useStoreState((state) => state.onlineAdmission.loading);

    const searchFormSubmit = (value) => {
        fetchsingleStudent(value.customStudentId);
    }

    const updateOnlineAdmission = (values) => {
        let postData = {
            "dob": moment(values.dob).format("YYYY-MM-DD"),
            "bloodGroup": values.bloodGroup,
            "academicSession": values.academicSession,
            "fatherName": values.fatherName,
            "studentPhotoFileContent": imageFileContent === undefined ? "R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" : imageFileContent,
            "studentImageName": imageFileName === undefined ? "no_image.png" : imageFileName,
            "studentPhotoSave": imageFileSave,
            "fatherPhotoFileContent": imageFileContentFather === undefined ? "R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" : imageFileContentFather,
            "fatherImageName": imageFileNameFather === undefined ? "no_image.png" : imageFileNameFather,
            "fatherPhotoSave": imageFileSaveFather,
            "motherPhotoFileContent": imageFileContentMother === undefined ? "R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" : imageFileContentMother,
            "motherImageName": imageFileNameMother === undefined ? "no_image.png" : imageFileNameMother,
            "motherPhotoSave": imageFileSaveMother,
            "guardianMobile": values.guardianMobile,
            "presentPostOffice": values.presentPostOffice,
            "studentId": singleStudent?.studentId,
            "motherName": values.motherName,
            "studentMobile": values.studentMobile,
            "studentName": values.studentName,
            "customStudentId": values.customStudentId,
            "guardianName": values.guardianName,
            /****Address objects***/
            "presentThanaId": presentThanaId,
            "presentVillege": values.presentVillege,
            "mailingAddress": values.mailingAddress,
            // other
        }
        updateSingleStudentBasic(postData);
    }

    const [districtList, setdistrictList] = useState<any>([]);
    const [thanaList, setPresentthanaList] = useState<any>([]);
    const [presentThanaId, setPresentThanaId] = useState<any>('');
    const [presentDisId, setPresentDisId] = useState<any>('');
    useEffect(() => {
        if (singleStudent !== null) {
            updateForm.setFieldsValue({
                studentName: singleStudent?.studentName,
                fatherName: singleStudent?.fatherName,
                motherName: singleStudent?.motherName,
                dob: singleStudent.dob == null ? null : moment(singleStudent.dob, 'YYYY-MM-DD'),
                guardianMobile: singleStudent?.guardianMobile,
                presentPostOffice: singleStudent?.presentPostOffice,
                dateOfBirth: singleStudent?.studentDOB,
                studentMobile: singleStudent?.studentMobile,
                bloodGroup: singleStudent?.bloodGroup,
                academicSession: singleStudent?.academicSession,
                customStudentId: singleStudent?.customStudentId,
                presentVillege: singleStudent?.presentVillege,
                mailingAddress: singleStudent?.mailingAddress,
                guardianName: singleStudent?.guardianName,
                presentDistrict: singleStudent?.presentDistrict,
                presentThanaId: singleStudent?.presentThanaId,
            });
            setTimeout(() => {
                setPresentDisId(singleStudent?.presentDistrictId);
                fetchpresentThanaId(singleStudent?.presentDistrictId, singleStudent?.presentThanaId);
                updateForm.setFieldsValue({
                    presentDistrictId: singleStudent?.presentDistrictId,
                })
            }, 1500);
        }

    }, [singleStudent])




    async function fetchpresentThanaId(disId, presentThanaIdx) {

        let thanaReponse: any = await fetch(
            `${process.env.REACT_APP_API_ROOT}/location/info/thana-list/by/district-id?districtId=${disId}`
        );
        thanaReponse = await thanaReponse.json();
        setPresentthanaList(thanaReponse?.item);
        setTimeout(() => {
            setPresentThanaId(presentThanaIdx);
            updateForm.setFieldsValue({
                presentThanaId: presentThanaIdx,
            })
        }, 1000);

    }

    const [imageFileContent, setimageFileContent] = useState<any>();
    const [imageFileSave, setimageFileSave] = useState<any>(false);
    const [imageFileName, setimageFileName] = useState<any>(undefined);

    const [imageFileContentFather, setimageFileContentFather] = useState<any>();
    const [imageFileSaveFather, setimageFileSaveFather] = useState<any>(false);
    const [imageFileNameFather, setimageFileNameFather] = useState<any>(undefined);

    const [imageFileContentMother, setimageFileContentMother] = useState<any>();
    const [imageFileSaveMother, setimageFileSaveMother] = useState<any>(false);
    const [imageFileNameMother, setimageFileNameMother] = useState<any>(undefined);

    function clearFileInput(ctrl) {
        try {
            ctrl.value = null;
        } catch (ex) { }
        if (ctrl.value) {
            ctrl.parentNode.replaceChild(ctrl.cloneNode(true), ctrl);
        }
    }
    const imageUpload = (e) => {
        e.preventDefault();
        const reader: any = new FileReader();
        const file = e.target.files[0];
        if (file?.size > 600000) {
            // file.target.value = null;
            clearFileInput(document.getElementById("imgfile"));
            setimageFileSave(false);
            setimageFileContent('');
            setimageFileName(undefined);
            alert("Image size should be less than 600KB");
            return;
        };
        if (reader !== undefined && file !== undefined) {
            setimageFileSave(true);

            reader.onloadend = () => {
                setimageFileName(file.name)
                setimageFileContent(reader.result.split(',')[1])
            }
            reader.readAsDataURL(file);
        }
    };

    const imageUploadFather = (e) => {
        e.preventDefault();
        const reader: any = new FileReader();
        const file = e.target.files[0];
        if (file?.size > 600000) {
            // file.target.value = null;
            clearFileInput(document.getElementById("imgfileFather"));
            setimageFileSaveFather(false);
            setimageFileContentFather('');
            setimageFileNameFather(undefined);
            alert("Image size should be less than 600KB");
            return;
        };
        if (reader !== undefined && file !== undefined) {
            setimageFileSaveFather(true);
            reader.onloadend = () => {
                setimageFileNameFather(file.name)
                setimageFileContentFather(reader.result.split(',')[1])
            }
            reader.readAsDataURL(file);
        }
    };

    const imageUploadMother = (e) => {
        e.preventDefault();
        const reader: any = new FileReader();
        const file = e.target.files[0];
        if (file?.size > 600000) {
            // file.target.value = null;
            clearFileInput(document.getElementById("imgfileMother"));
            setimageFileSaveMother(false);
            setimageFileContentMother('');
            setimageFileNameMother(undefined);
            alert("Image size should be less than 600KB");
            return;
        };
        if (reader !== undefined && file !== undefined) {
            setimageFileSaveMother(true);
            reader.onloadend = () => {
                setimageFileNameMother(file.name)
                setimageFileContentMother(reader.result.split(',')[1])
            }
            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        async function fetchMyAPI() {
            let disReponse: any = await fetch(`${process.env.REACT_APP_API_ROOT}/location/info/district-list`)
            disReponse = await disReponse.json()
            setdistrictList(disReponse?.item)
        }

        fetchMyAPI();

    }, []);

    async function handlePresentDistrict(evt) {
        setPresentDisId(evt)
        setPresentThanaId(null);
        updateForm.setFieldsValue({
            presentThanaId: null,
        })
        let thanaReponse: any = await fetch(`${process.env.REACT_APP_API_ROOT}/location/info/thana-list/by/district-id?districtId=${evt}`)
        thanaReponse = await thanaReponse.json()
        setPresentthanaList(thanaReponse?.item)
    }

    async function handlePresentThana(evt) {
        setPresentThanaId(evt)
        updateForm.setFieldsValue({
            presentThanaId: evt,
        })
    }

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [identificationId, setidentificationId] = useState<any>();
    const [classConfigurationId, setclassConfigurationId] = useState<any>();
    const [groupId, setgroupId] = useState<any>();
    const [academicYear, setacademicYear] = useState<any>();

    const columns = [
        {
            title: 'Class-Shift-Section',
            dataIndex: 'classConfigurationName',
            key: 'classConfigurationName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Group',
            dataIndex: 'groupName',
            key: 'groupName',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Roll',
            dataIndex: 'studentRoll',
            key: 'studentRoll',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Academic Year',
            dataIndex: 'academicYear',
            key: 'academicYear',
            showOnResponse: true,
            showOnDesktop: true
        },
        {
            title: 'Action',
            key: 'identificationId',
            showOnResponse: true,
            showOnDesktop: true,
            render: (text: any, record: any, index) => (
                <Space size="middle">
                    <Tooltip title="Edit">
                        <Button type='primary' icon={<EditOutlined />} onClick={() => {
                            setIsModalVisible(true);
                            setidentificationId(record.identificationId);
                            setclassConfigurationId(record.classConfigurationId);
                            setgroupId(record.groupId);
                            setacademicYear(record.academicYear);
                            updateFormTable.setFieldsValue({
                                studentRoll: record.studentRoll,
                                classConfigurationId: record.classConfigurationId,
                                groupId: record.groupId,
                                academicYear: record.academicYear,
                            });
                            setTimeout(() => {
                                fetchlistGroupbyClassConfig(record.classConfigurationId);
                            }, 1000);
                        }} />
                    </Tooltip>
                    <Tooltip title="Download">
                        <Button type='primary' icon={<DownloadOutlined />} onClick={() => downloadStudentIdCard(record?.identificationId)} />
                    </Tooltip>
                </Space>
            ),
        }
    ]
    const [updateFormTable] = Form.useForm();

    const updateSubmitFormTable = (value) => {
        let updatePostData = {
            "academicYear": academicYear,
            "classConfigurationId": classConfigurationId,
            "groupId": groupId,
            "identificationId": identificationId,
            "studentRoll": value?.studentRoll
        }
        updateSingleStudentIdentification(updatePostData);
        setIsModalVisible(false);
        updateFormTable.resetFields();
        searchStudentForm.submit();
    }


    return (
        <>
            <Card title="Student Information">
                <Row>
                    <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24, offset: 0 }} md={{ span: 24 }} lg={{ span: 12, offset: 6 }} xl={{ span: 12, offset: 6 }}>
                        <Form
                            layout="vertical"
                            id="onlineAdmission"
                            onFinish={searchFormSubmit}
                            form={searchStudentForm}
                        >
                            <Row>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 6 }}>

                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} lg={{ span: 12 }} xl={{ span: 12 }}>
                                    <Form.Item
                                        name="customStudentId"
                                        label="Student ID"
                                        className="title-Text"
                                        rules={[
                                            { required: true, message: "Please write student id" },
                                        ]}
                                    >
                                        <Input placeholder='Student id' />
                                    </Form.Item>
                                </Col>
                                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                                    <Button type="primary" htmlType="submit" icon={<SearchOutlined />} >
                                        Search
                                    </Button>

                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>

                <Skeleton loading={loading} paragraph={{ rows: 4 }} />
                {singleStudent !== null &&

                    <Form
                        layout="vertical"
                        id="onlineAdmissionUpdate"
                        onFinish={updateOnlineAdmission}
                        form={updateForm}
                    >
                        <Row>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="customStudentId"
                                    label="Student ID"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please enter Student ID" },
                                    ]}
                                >
                                    <Input placeholder="Student ID" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="studentName"
                                    label="Student Name"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please enter student name" },
                                    ]}
                                >
                                    <Input placeholder="Student name" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="academicSession"
                                    label="Academic Session"
                                    className="title-Text"
               
                                >
                                    <Input placeholder="Academic Session" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="bloodGroup"
                                    label="Blood Group"
                                    className="title-Text"
                                >
                                    <Select
                                        placeholder="Select Blood Group"
                                    >
                                        {bloodData?.map((value) => (
                                            <Option key={value} value={value}  >
                                                {value}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="dob"
                                    label="Date of Birth:"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please select birth date" },
                                    ]}
                                >
                                    <DatePicker style={{ width: '100%' }} placeholder="Select Date" format={"DD/MM/YYYY"} />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="studentMobile"
                                    label="Student Mobile"
                                    className="title-Text"
                                >
                                    <Input placeholder="Student Mobile" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="fatherName"
                                    label="Father Name"
                                    className="title-Text"
                                >
                                    <Input placeholder="Father Name" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="motherName"
                                    label="Mother Name"
                                    className="title-Text"
                                >
                                    <Input placeholder="Mother Name" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="guardianName"
                                    label="Guardian Name"
                                    className="title-Text"
                                >
                                    <Input placeholder="Guardian Name" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="guardianMobile"
                                    label="Guardian Mobile"
                                    className="title-Text"
                                    rules={[
                                        { required: true, message: "Please enter  Guardian Mobile" },
                                    ]}
                                >
                                    <Input placeholder="Guardian Mobile" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="mailingAddress"
                                    label="Mailing Address"
                                    className="title-Text"
                                >
                                    <Input placeholder="Mailing Address" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="presentVillege"
                                    label="Present Village"
                                    className="title-Text"
                                >
                                    <Input placeholder="Present Village" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="presentPostOffice"
                                    label="Present Post Office"
                                    className="title-Text"
                                >
                                    <Input placeholder="Present Post Office" />
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="presentDistrictId"
                                    label="District"
                                    className="title-Text"
                                >
                                    <Select
                                        placeholder="Select District"
                                        onChange={(e) => {
                                            handlePresentDistrict(e);
                                        }}
                                    >
                                        {districtList?.map((value) => (
                                            <Option key={value?.districtId} value={value?.districtId}  >
                                                {value?.districtName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                                <Form.Item
                                    name="presentThanaId"
                                    label="Thana"
                                    className="title-Text"
                                >
                                    <Select
                                        placeholder="Select Thana"
                                        onChange={(e) => {
                                            handlePresentThana(e);
                                        }}
                                    >
                                        {thanaList?.map((value) => (
                                            <Option key={value?.thanaId} value={value?.thanaId}  >
                                                {value?.thanaName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>


                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                                {singleStudent?.photoLink != null ? <><img className="photoLink" src={singleStudent?.photoLink} height={50} width={50} alt="photoLink" style={{ marginTop: 20 }} /> <br /> </> : <> <span>No Photo </span> <br /> </>}
                                Student Photo
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <input id="imgfile" style={{ marginTop: 20 }} name="file" type="file" accept="image/*" onChange={(e) => {
                                    imageUpload(e)
                                }} />
                            </Col>

                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                                {singleStudent?.fatherPhotoLink != null ? <><img className="fatherPhotoLink" src={singleStudent?.fatherPhotoLink} height={50} width={50} alt="fatherPhotoLink" style={{ marginTop: 20 }} /> <br /> </> : <> <span>No Photo </span> <br /> </>}
                                Father Photo
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <input id="imgfile" style={{ marginTop: 20 }} name="file" type="file" accept="image/*" onChange={(e) => {
                                    imageUploadFather(e)

                                }} />
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 2 }} xl={{ span: 2 }}>
                                {singleStudent?.motherPhotoLink != null ? <><img className="motherPhotoLink" src={singleStudent?.motherPhotoLink} height={50} width={50} alt="motherPhotoLink" style={{ marginTop: 20 }} />  <br /> </> : <> <span>No Photo </span> <br /> </>}
                                Mother Photo
                            </Col>
                            <Col xs={{ span: 24, offset: 0 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <input id="imgfile" style={{ marginTop: 20 }} name="file" type="file" accept="image/*" onChange={(e) => {
                                    imageUploadMother(e)

                                }} />
                            </Col>

                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 20 }} lg={{ span: 20 }} xl={{ span: 20 }}> </Col>
                            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }} xl={{ span: 4 }}>

                                <Button type="primary" htmlType="submit" icon={<SaveOutlined />} >
                                    Update
                                </Button>

                            </Col>
                        </Row>
                    </Form>

                }
                <br />
                <Row className="m-t-mo-30">
                    <Col span="24">
                        <div className="datatable-responsive-demo">
                            {singleStudent?.identifications?.length > 0 &&
                                <TableView
                                    antTableProps={{
                                        showHeader: true,
                                        columns,
                                        dataSource: singleStudent?.identifications,
                                        filterData: singleStudent?.identifications,
                                        pagination: true,
                                        bordered: true,
                                        rowKey: "id",
                                    }}
                                    mobileBreakPoint={768}
                                />
                            }
                        </div>
                    </Col>
                </Row>
            </Card>
            <Modal
                title="Update"
                visible={isModalVisible}
                //  onOk={handleOk}
                okButtonProps={{ form: 'update', htmlType: 'submit' }}
                onCancel={() => setIsModalVisible(false)}
                cancelText="Close"
                okText="Update"
                centered
                maskClosable={false}
            >
                <Form
                    layout="vertical"
                    id="update"
                    onFinish={updateSubmitFormTable}
                    form={updateFormTable}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="classConfigurationId"
                                label="Class Shift Section"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select" },
                                ]}
                            >
                                <SelectClassConfig3 selected={classConfigurationId} onChange={e => { setclassConfigurationId(e); fetchlistGroupbyClassConfig(e); setgroupId(null) }} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="groupId"
                                label="Group"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select" },
                                ]}
                            >
                                <SelectGroupbySection selected={groupId} onChange={e => setgroupId(e?.groupId)} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="studentRoll"
                                label="Roll"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please input" },
                                ]}
                            >
                                <Input placeholder='Student Roll' />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="academicYear"
                                label="Academic Year"
                                className="title-Text"
                                rules={[
                                    { required: true, message: "Please select" },
                                ]}
                            >
                                <SelectAcademicYear4 selected={academicYear} onChange={e => setacademicYear(e)} />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </Modal>
        </>
    )
}