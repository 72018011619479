import React from 'react'
import { Card, Tabs } from 'antd';

import ZketecoStaffProcess from './ZketecoStaffProcess';

export default function StaffAttendanceMachine(props) {

    const { TabPane } = Tabs;
    const [activeTab, setActiveTab] = React.useState<any>("1");

    return (
        <>
            <Card title="Machine Process">
                
                <Tabs defaultActiveKey="1"  onChange={(e) => { setActiveTab(e) }}>
                    
                    <TabPane tab="Zkteco" key="1">
                        {activeTab === "1" && <ZketecoStaffProcess />} 
                    </TabPane>

                    
                    
                </Tabs>

            </Card>
        </>
    )
}